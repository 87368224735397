<template>
  <div>
 
    <div>
      <v-layout>
        <v-flex style="z-index:10;">
          <servicesHome />
        </v-flex>
      </v-layout>

      <v-card v-if="itemsCarousel.length > 0">

        <div class="carousel-container hidden-xs-only">
          <OwlCarousel :nav="false" :dots="false" :loop="true" :autoplay="true" :autoplaySpeed="1000"
            :autoplayTimeout="5000" :animateOut="'fadeOut'" :responsive="owlResponsive">
            <template v-for="(list, l) in itemsCarousel">
              <v-layout wrap justify-center v-if="list" :key="l">
                <v-flex>
                  <v-img :height="$vuetify.breakpoint.name == 'xs'
                    ? '800px'
                    : $vuetify.breakpoint.name == 'sm'
                      ? '800px'
                      : $vuetify.breakpoint.name == 'md'
                        ? '600px'
                        : '100%'" :src="list.src" eager></v-img>
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>
          <v-layout wrap justify-center class="fixed-overlay-content hidden-xs-only">
            <v-flex xs12 pa-8 data-aos="zoom-in-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500">
              <v-layout wrap justify-center>
                <v-flex xs12 sm12 md10 pt-md-0 pt-sm-10 >

                  <span style="color: black; text-transform: uppercase; font-family: OutfitSemiBold;" :style="{


                    'font-size':
                    $vuetify.breakpoint.name == 'xs' ? '13px' :
                      $vuetify.breakpoint.name == 'sm' ? '15px' :
                        $vuetify.breakpoint.name == 'md' ? '15px' :
                        $vuetify.breakpoint.name == 'lg' ? '16px' : '30px',


                  }"> Kumarakom
                    Ventures</span>
                  <br />
                  <v-flex pt-lg-2 pt-sm-2 pt-md-2 lg11>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1A2421;text-align:justify;"
                      :style="{
                        'line-height':
                          $vuetify.breakpoint.name == 'xs' ? '30px' :
                            $vuetify.breakpoint.name == 'sm' ? '30px' :
                              $vuetify.breakpoint.name == 'md' ? '30px' : '50px',

                              'font-size':
                              $vuetify.breakpoint.name == 'xs' ? '20px' :
                                $vuetify.breakpoint.name == 'sm' ? '22px' :
                                  $vuetify.breakpoint.name == 'md' ? '25px' :
                                  $vuetify.breakpoint.name == 'lg' ? '35px' : '48px',

                        
                      }">
                      Where Success Knows No Bounds, And <span style="color: #1B6DC1;">Possibilities </span>Are
                      <span style="color: #1B6DC1;">Limitless</span>.
                    </span>
                    <br />
                    <v-flex xs12 pt-lg-3 pt-md-2>
                      <span style="color: #000; font-family: JostRegular; text-align:justify" :style="{
                        'line-height':
                          $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'xl' ? '40px' : '25px',
                        'font-size':
                          $vuetify.breakpoint.name == 'xs' ? '15px' : $vuetify.breakpoint.name == 'xl' ? '30px' : '15px',
                      }">Kumarakom Ventures is a dynamic business aiming to deliver exceptional services globally.
                        Our goal
                        is
                        to
                        contribute to India's growth by empowering clients with knowledge and opportunities. We
                        achieve this
                        through innovative solutions and personalized services, fostering long-term partnerships for
                        sustainable growth.
                      </span></v-flex>
                  </v-flex>
                </v-flex>


                <v-flex  xs12 sm12 md10 pt-xl-7 pt-md-6 pt-0 pt-sm-0 :style="{
                  'padding':
                    $vuetify.breakpoint.name == 'xs' ? '20px' : ($vuetify.breakpoint.name == 'sm' ? '20px' : '0px'),

                }">


                  <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1A2421;"
                    :style="{
                      'line-height':
                        $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'md' ? '20px' : '40px',

                        'font-size':
                        $vuetify.breakpoint.name == 'xs' ? '20px' :
                          $vuetify.breakpoint.name == 'sm' ? '22px' :
                            $vuetify.breakpoint.name == 'md' ? '20px' :
                            $vuetify.breakpoint.name == 'lg' ? '20px' : '40px',

                     
                    }">
                    <span style="color: #1B6DC1;">Empowering Growth: Our Vision for a Prosperous </span>Future

                  </span>
                  <br />
                  <v-flex text-justify lg11 pt-0 pt-xl-8>
                    <span style="color: #000; font-family: JostRegular; text-align:justify" :style="{
                      'line-height':
                      
                       

                        $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'xl' ? '40px' : '25px',
                        
                      'font-size':

                      $vuetify.breakpoint.name == 'xs' ? '12px' :
                      $vuetify.breakpoint.name == 'sm' ? '14px' :
                        $vuetify.breakpoint.name == 'md' ? '14px' :
                        $vuetify.breakpoint.name == 'lg' ? '15px' : '30px',

                      
                    }">At Kumarakom Ventures, our overarching goal is to be a valuable contributor to the
                      fastest-growing Indian economy by empowering our clients with knowledge and confidence, thus
                      linking them to economically beneficial opportunities around the world. We strive to achieve
                      this by providing innovative solutions, strategic insights, and personalized services tailored
                      to the unique needs of each client. Through our dedication to excellence and commitment to
                      fostering long-term partnerships, we aim to drive sustainable growth for our clients, our
                      community, and our nation.
                    </span></v-flex>

                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-card>

      <!-- Mobil Screen -->

      <v-card v-if="itemsCarousel.length > 0">

        <div class=" hidden-sm-and-up">
          
          <v-layout wrap justify-center class=" hidden-sm-and-up" style="background-color: #c1e0ff">

            <v-flex  xs12 data-aos="zoom-in-up" data-aos-ease="ease" data-aos-duration="1500" data-aos-delay="500">
              <v-layout>
                <v-flex  xs12 :style="{
                  'padding':
                    $vuetify.breakpoint.name == 'xs' ? '20px' : ($vuetify.breakpoint.name == 'sm' ? '20px' : '15px'),
    
                }">
                  <v-flex px-5>
                  <span style="color: black; text-transform: uppercase; font-family: OutfitSemiBold;" :style="{
    
                    'font-size':
                      $vuetify.breakpoint.name == 'xs' ? '13px' : $vuetify.breakpoint.name == 'md' ? '15px' : '16px',
                  }"> Kumarakom
                    Ventures</span>
                  </v-flex>
                  
                  <v-flex pt-3 px-5>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1A2421;text-align:justify;"
                      :style="{
                        'line-height':
                          $vuetify.breakpoint.name == 'xs' ? '30px' :
                            $vuetify.breakpoint.name == 'sm' ? '30px' :
                              $vuetify.breakpoint.name == 'md' ? '10px' : '50px',
                        'font-size':
                          $vuetify.breakpoint.name == 'xs' ? '20px' :
                            $vuetify.breakpoint.name == 'sm' ? '22px' :
                              $vuetify.breakpoint.name == 'md' ? '20px' : '40px',
                      }">
                      Where Success Knows No Bounds, And <span style="color: #1B6DC1;">Possibilities </span>Are
                      <span style="color: #1B6DC1;">Limitless</span>.
                    </span>
                    <br />
                    <v-flex xs12 text-justify>
                      <span style="color: #000; font-family: JostRegular; text-align:justify" :style="{
                        'line-height':
                          $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'xl' ? '25px' : '25px',
                        'font-size':
                          $vuetify.breakpoint.name == 'xs' ? '15px' : $vuetify.breakpoint.name == 'xl' ? '30px' : '15px',
                      }">Kumarakom Ventures is a dynamic business aiming to deliver exceptional services globally.
                        Our goal
                        is
                        to
                        contribute to India's growth by empowering clients with knowledge and opportunities. We
                        achieve this
                        through innovative solutions and personalized services, fostering long-term partnerships for
                        sustainable growth.
                      </span></v-flex>
                  </v-flex>
                  <v-flex pt-5 px-5 text-jus:style="{
                    'padding':
                      $vuetify.breakpoint.name == 'xs' ? '20px' : ($vuetify.breakpoint.name == 'sm' ? '20px' : '10px'),
    
                  }">
    
    
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1A2421;text-align:justify" :style="{
                      'line-height':
                        $vuetify.breakpoint.name == 'xs' ? '30px' : $vuetify.breakpoint.name == 'md' ? '20px' : '40px',
                      'font-size':
                        $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'md' ? '18px' : '23px',
                    }">
                      <span style="color: #1B6DC1;">Empowering Growth: Our Vision for a Prosperous </span>Future
    
                    </span>
                    <br />
                    <v-flex text-justify>
                      <span style="color: #000; font-family: JostRegular; text-align:justify" :style="{
                        'line-height':
                          $vuetify.breakpoint.name == 'xs' ? '20px' : $vuetify.breakpoint.name == 'md' ? '17px' : '25px',
                        'font-size':
                          $vuetify.breakpoint.name == 'xs' ? '15px' : $vuetify.breakpoint.name == 'md' ? '14px' : '15px',
                      }">At Kumarakom Ventures, our overarching goal is to be a valuable contributor to the
                        fastest-growing Indian economy by empowering our clients with knowledge and confidence, thus
                        linking them to economically beneficial opportunities around the world. We strive to achieve
                        this by providing innovative solutions, strategic insights, and personalized services tailored
                        to the unique needs of each client. Through our dedication to excellence and commitment to
                        fostering long-term partnerships, we aim to drive sustainable growth for our clients, our
                        community, and our nation.
                      </span></v-flex>
    
                  </v-flex>
                </v-flex>
                
              </v-layout>
            </v-flex>
         
          
          </v-layout>

          <OwlCarousel :nav="false" :dots="false" :loop="true" :autoplay="true" :autoplaySpeed="1000"
            :autoplayTimeout="5000" :animateOut="'fadeOut'" :responsive="owlResponsive">
            <template v-for="(list, l) in itemsCarousel1">
              <v-layout wrap justify-center v-if="list" :key="l">
                <v-flex>
                  <v-img height="300px" :src="list.src" eager></v-img>
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>
        </div>
      </v-card>






     

      <!-- ------ -->

    </div>
    <!-- -------------------------------- -->
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md11>
        <AboutUs />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center :style="{
      background: 'linear-gradient(356deg, #E1EEFF87 20%, #E1EEFF87 8%, #FFFFFF 35%, #FFFFFF 0%)',

    }">
      <v-flex xs12 sm12 md10>
        <services />
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center py-10 :style="{
      background: 'linear-gradient(10deg, #E1EEFF 0%, #E1EEFF 47%, #FFFFFF 48%, #FFFFFF 100%)',
    }">
      <v-flex xs12 sm12 md9 lg6 py-12>
        <imageSlider />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AboutUs from "./aboutUs";
import services from "./services";
import imageSlider from "./imageSlider"
import servicesHome from "./servicesHome"




export default {
  components: {
    AboutUs,
    services,
    imageSlider,
    servicesHome,
  },
  data() {
    return {
      // pdfUrl:require('@/assets/Empower_Steel_Tahiland.pdf'),
      expandedChat: false,
      expandedCall: false,
      callMarginTop: '20px',
      chatMarginTop: '80px',
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },

      itemsCarousel: [
        {
          src: require("./../../assets/images/fc1.png"),
        },

        {
          src: require("./../../assets/images/fc2.png"),
        },
        {
          src: require("./../../assets/images/fc3.png"),
        },
      ],

      itemsCarousel1: [
        {
          src: require("./../../assets/images/mainhomephn1.png"),
        },

        {
          src: require("./../../assets/images/mainhomephn2.png"),
        },
        {
          src: require("./../../assets/images/mainhomephn3.png"),
        },
      ],
      // items: [
      //   {
      //     icon: require("./../../assets/images/h1.png"),
      //     name: "Rubicon Career Development",
      //     route: "/home"
      //   },
      //   {
      //     icon: require("./../../assets/images/h2.png"),
      //     name: "Real Estate",
      //     route: "/realestate"
      //   },
      //   {
      //     icon: require("./../../assets/images/h3.png"),
      //     name: "Business Strategy",
      //     route: "/"
      //   },
      //   {
      //     icon: require("./../../assets/images/h4.png"),
      //     name: "Health - Counselling services",
      //     route: "/"
      //   },
      //   {
      //     icon: require("./../../assets/images/h5.png"),
      //     name: "Global - Steel Procurement",
      //     route: "/"
      //   },
      //   {
      //     icon: require("./../../assets/images/h6.png"),
      //     name: "Website & IT",
      //     route: "/"
      //   },
      // ],
    };
  },
  methods: {

    // toggleExpanded(type) {
    //   if (type === 'chat') {
    //     this.expandedChat = !this.expandedChat;
    //     this.chatMarginTop = this.expandedChat ? '80px' : '80px';
    //   } else if (type === 'call') {
    //     this.expandedCall = !this.expandedCall;
    //     this.callMarginTop = this.expandedCall ? '20px' : '20px';
    //   }
    // }

  },

}

</script>
<style scoped>
.carousel-container {
  position: relative;
}

.fixed-overlay-content {
  position: absolute;
  top: 45%;
  left: 30%;
  transform: translate(-45%, -50%);


  z-index: 5;
}

.owl-carousel .owl-item {
  transition: opacity 1s ease-in-out;
}

.owl-carousel .owl-item.fadeOut {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>