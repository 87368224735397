<template>
  <div>
    <v-layout wrap justify-center pb-16 >
      <v-flex xs12 text-center pt-2>
        <span 
          style="
            font-family: OutfitExtraBold;
            color: #1C6DC1;
            text-transform: uppercase;
            font-size: 20px;
            
          "
          >Core Values</span
        >
      </v-flex>
      <v-flex xs12 text-center pt-2>
        <span
          style="font-family: OutfitExtraBold; color: #1A2421; font-size: 35px"
          >Our core values guide our actions, decisions, and interactions</span
        >
      </v-flex>
      <v-layout wrap justify-center pt-5>
        <v-flex xs12 sm6 md4 pa-5 v-for="(item, i) in items" :key="i">
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <!-- <v-icon size="40" :color="hover ? 'white' : '#1B6DC1'">{{
                item.icon
              }}</v-icon> -->
              <v-img contain :src="item.icon" width="30px" height="30px"></v-img>

            </v-flex>
            <v-flex xs12 pt-3>
              <span
                style="font-family: OutfitBold; font-size: 20px; color: black"
                >{{ item.name }}</span
              >
            </v-flex>
            <v-flex xs12 pt-3 text-justify>
              <span style="font-family: JostRegular; color: black ; font-size: 15px;">{{
                item.content
              }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      items: [
        {
          icon: require("./../../assets/images/v1.png"),
          name: "Innovation",
          content:
            "We embrace creativity and forward thinking, constantly seeking new ideas, approaches, and solutions to drive progress and achieve excellence.",
        },
        {
          icon: require("./../../assets/images/v2.png"),
          name: "Integrity",
          content:
            "We uphold the highest standards of honesty, transparency, and ethical behavior in all our endeavors, earning the trust and respect of our partners, clients, and communities.",
        },
        {
          // icon: "mdi-account-switch",
          icon: require("./../../assets/images/v3.png"),
          name: "Collaboration",
          content:
            "We believe in the power of collaboration and teamwork, recognizing that diverse perspectives and collective efforts lead to greater innovation and success.",
        },
        {
          icon: require("./../../assets/images/v4.png"),
          name: "Excellence",
          content:
            "We are committed to delivering exceptional quality and value in everything we do, striving for excellence in execution, performance, and outcomes.",
        },
        {
          icon: require("./../../assets/images/v5.png"),
          name: "Empowerment",
          content:
            "We empower individuals and organizations to reach their full potential, fostering an environment that encourages growth, learning, and personal development.",
        },
        {
          icon: require("./../../assets/images/v6.png"),
          name: "Social Responsibility",
          content:
            "We are dedicated to making a positive impact on society and the environment, actively engaging in initiatives that promote sustainability, diversity, inclusion, and social justice.",
        },
        {
          icon: require("./../../assets/images/v7.png"),
          name: "Adaptability-Reliability",
          content:
            "With a steadfast commitment to doing what we promise, we demonstrate flexibility, resilience, and agility in navigating evolving landscapes, ensuring superior results and exceeding expectations every step of the way.",
        },
        
        {
          icon: require("./../../assets/images/v8.png"),
          name: "Customer-Centricity",
          content:
            "We prioritize the needs and interests of our clients and partners, striving to exceed their expectations and build enduring relationships based on mutual trust, respect, and shared success.",
        },
       
        {
          icon: require("./../../assets/images/v9.png"),
          name: "Quality Assurance ",
          content:
            "We have dedicated quality assurance measures in place to monitor and assess the quality of our deliverables. Quality checks, reviews, and audits are conducted at various stages to ensure adherence to standards and specifications.",
        },
      ],
    };
  },
};
</script>