var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-0"},[_c('v-layout',{staticClass:"hidden-sm-and-down",staticStyle:{"max-width":"800px","margin-left":"50px"},style:({
            'margin-left': (_vm.$vuetify.breakpoint.name == 'xl') ? '300px' : (_vm.$vuetify.breakpoint.name == 'md') ? '50px' : '50px'
        }),attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{staticStyle:{"position":"relative"},attrs:{"xs12":"","sm12":"","md10":"","lg12":"","xl12":"","py-2":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{staticClass:"hidden-md-only",staticStyle:{"position":"relative","left":"-170px"},attrs:{"xs6":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/imgslider1.png"),"width":"300px","height":"320px"}})],1),_c('v-flex',{staticClass:"second-image",staticStyle:{"position":"relative","left":"160px"},attrs:{"xs6":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/imgslider2.png"),"width":"300px","height":"320px"}})],1)],1),_c('v-img',{staticStyle:{"position":"absolute","top":"-35px","left":"0"},attrs:{"src":require("./../../assets/images/imgslider3.png"),"width":"350px","height":"400px"}}),_c('v-card',{staticStyle:{"border-radius":"0%","position":"absolute","top":"-35px","left":"350px","width":"300px","height":"400px","background":"linear-gradient(356deg, #53A2F2 100%, #1F7DDC 100%)"}},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","py-8":"","px-6":"","align-self-center":""}},[_c('span',{staticStyle:{"color":"#FFFFFF","font-size":"18px","font-family":"JostRegular"}},[_vm._v(" We aim to deliver superior results, exceed client expectations, and drive successful outcomes for every project we undertake. ")]),_c('hr',{staticStyle:{"border-top":"2px solid #FFFFFF","margin-top":"10px"}}),_c('br'),_c('span',{staticStyle:{"color":"#FFFFFF","font-size":"12px","font-family":"JostRegular"}},[_vm._v(" We strive to not just meet but surpass expectations, consistently delivering superior results and ensuring successful outcomes for every project entrusted to us. Your satisfaction is our driving force. ")])])],1)],1)],1),_c('v-layout',{staticClass:"hidden-md-and-up",style:({
            'padding': (_vm.$vuetify.breakpoint.name == 'xs') ? '10px' : (_vm.$vuetify.breakpoint.name == 'sm') ? '10px' : '20px',
            
           
        }),attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/imgslider3.png")}}),_c('v-card',{style:({
                    background: 'linear-gradient(356deg, #53A2F2 100%, #1F7DDC 100%)',
                })},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","py-10":"","px-10":"","align-self-center":""}},[_c('span',{staticStyle:{"color":"#FFFFFF","font-size":"22px","font-family":"JostRegular"}},[_vm._v(" We aim to deliver superior results, exceed client expectations, and drive successful outcomes for every project we undertake. ")]),_c('hr',{staticStyle:{"border-top":"2px solid #FFFFFF","margin-top":"13px"}}),_c('br'),_c('span',{staticStyle:{"color":"#FFFFFF","font-size":"13px","font-family":"JostRegular"}},[_vm._v(" We strive to not just meet but surpass expectations, consistently delivering superior results and ensuring successful outcomes for every project entrusted to us. Your satisfaction is our driving force ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }