var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-layout',[_c('v-flex',{staticStyle:{"z-index":"10"}},[_c('servicesHome')],1)],1),(_vm.itemsCarousel.length > 0)?_c('v-card',[_c('div',{staticClass:"carousel-container hidden-xs-only"},[_c('OwlCarousel',{attrs:{"nav":false,"dots":false,"loop":true,"autoplay":true,"autoplaySpeed":1000,"autoplayTimeout":5000,"animateOut":'fadeOut',"responsive":_vm.owlResponsive}},[_vm._l((_vm.itemsCarousel),function(list,l){return [(list)?_c('v-layout',{key:l,attrs:{"wrap":"","justify-center":""}},[_c('v-flex',[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
                  ? '800px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                    ? '800px'
                    : _vm.$vuetify.breakpoint.name == 'md'
                      ? '600px'
                      : '100%',"src":list.src,"eager":""}})],1)],1):_vm._e()]})],2),_c('v-layout',{staticClass:"fixed-overlay-content hidden-xs-only",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-8":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","pt-md-0":"","pt-sm-10":""}},[_c('span',{staticStyle:{"color":"black","text-transform":"uppercase","font-family":"OutfitSemiBold"},style:({


                  'font-size':
                  _vm.$vuetify.breakpoint.name == 'xs' ? '13px' :
                    _vm.$vuetify.breakpoint.name == 'sm' ? '15px' :
                      _vm.$vuetify.breakpoint.name == 'md' ? '15px' :
                      _vm.$vuetify.breakpoint.name == 'lg' ? '16px' : '30px',


                })},[_vm._v(" Kumarakom Ventures")]),_c('br'),_c('v-flex',{attrs:{"pt-lg-2":"","pt-sm-2":"","pt-md-2":"","lg11":""}},[_c('span',{staticStyle:{"font-family":"OutfitSemiBold","font-weight":"bold","color":"#1A2421","text-align":"justify"},style:({
                      'line-height':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '30px' :
                          _vm.$vuetify.breakpoint.name == 'sm' ? '30px' :
                            _vm.$vuetify.breakpoint.name == 'md' ? '30px' : '50px',

                            'font-size':
                            _vm.$vuetify.breakpoint.name == 'xs' ? '20px' :
                              _vm.$vuetify.breakpoint.name == 'sm' ? '22px' :
                                _vm.$vuetify.breakpoint.name == 'md' ? '25px' :
                                _vm.$vuetify.breakpoint.name == 'lg' ? '35px' : '48px',

                      
                    })},[_vm._v(" Where Success Knows No Bounds, And "),_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Possibilities ")]),_vm._v("Are "),_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Limitless")]),_vm._v(". ")]),_c('br'),_c('v-flex',{attrs:{"xs12":"","pt-lg-3":"","pt-md-2":""}},[_c('span',{staticStyle:{"color":"#000","font-family":"JostRegular","text-align":"justify"},style:({
                      'line-height':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'xl' ? '40px' : '25px',
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '15px' : _vm.$vuetify.breakpoint.name == 'xl' ? '30px' : '15px',
                    })},[_vm._v("Kumarakom Ventures is a dynamic business aiming to deliver exceptional services globally. Our goal is to contribute to India's growth by empowering clients with knowledge and opportunities. We achieve this through innovative solutions and personalized services, fostering long-term partnerships for sustainable growth. ")])])],1)],1),_c('v-flex',{style:({
                'padding':
                  _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : (_vm.$vuetify.breakpoint.name == 'sm' ? '20px' : '0px'),

              }),attrs:{"xs12":"","sm12":"","md10":"","pt-xl-7":"","pt-md-6":"","pt-0":"","pt-sm-0":""}},[_c('span',{staticStyle:{"font-family":"OutfitSemiBold","font-weight":"bold","color":"#1A2421"},style:({
                    'line-height':
                      _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'md' ? '20px' : '40px',

                      'font-size':
                      _vm.$vuetify.breakpoint.name == 'xs' ? '20px' :
                        _vm.$vuetify.breakpoint.name == 'sm' ? '22px' :
                          _vm.$vuetify.breakpoint.name == 'md' ? '20px' :
                          _vm.$vuetify.breakpoint.name == 'lg' ? '20px' : '40px',

                   
                  })},[_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Empowering Growth: Our Vision for a Prosperous ")]),_vm._v("Future ")]),_c('br'),_c('v-flex',{attrs:{"text-justify":"","lg11":"","pt-0":"","pt-xl-8":""}},[_c('span',{staticStyle:{"color":"#000","font-family":"JostRegular","text-align":"justify"},style:({
                    'line-height':
                    
                     

                      _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'xl' ? '40px' : '25px',
                      
                    'font-size':

                    _vm.$vuetify.breakpoint.name == 'xs' ? '12px' :
                    _vm.$vuetify.breakpoint.name == 'sm' ? '14px' :
                      _vm.$vuetify.breakpoint.name == 'md' ? '14px' :
                      _vm.$vuetify.breakpoint.name == 'lg' ? '15px' : '30px',

                    
                  })},[_vm._v("At Kumarakom Ventures, our overarching goal is to be a valuable contributor to the fastest-growing Indian economy by empowering our clients with knowledge and confidence, thus linking them to economically beneficial opportunities around the world. We strive to achieve this by providing innovative solutions, strategic insights, and personalized services tailored to the unique needs of each client. Through our dedication to excellence and commitment to fostering long-term partnerships, we aim to drive sustainable growth for our clients, our community, and our nation. ")])])],1)],1)],1)],1)],1)]):_vm._e(),(_vm.itemsCarousel.length > 0)?_c('v-card',[_c('div',{staticClass:"hidden-sm-and-up"},[_c('v-layout',{staticClass:"hidden-sm-and-up",staticStyle:{"background-color":"#c1e0ff"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('v-layout',[_c('v-flex',{style:({
                'padding':
                  _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : (_vm.$vuetify.breakpoint.name == 'sm' ? '20px' : '15px'),
  
              }),attrs:{"xs12":""}},[_c('v-flex',{attrs:{"px-5":""}},[_c('span',{staticStyle:{"color":"black","text-transform":"uppercase","font-family":"OutfitSemiBold"},style:({
  
                  'font-size':
                    _vm.$vuetify.breakpoint.name == 'xs' ? '13px' : _vm.$vuetify.breakpoint.name == 'md' ? '15px' : '16px',
                })},[_vm._v(" Kumarakom Ventures")])]),_c('v-flex',{attrs:{"pt-3":"","px-5":""}},[_c('span',{staticStyle:{"font-family":"OutfitSemiBold","font-weight":"bold","color":"#1A2421","text-align":"justify"},style:({
                      'line-height':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '30px' :
                          _vm.$vuetify.breakpoint.name == 'sm' ? '30px' :
                            _vm.$vuetify.breakpoint.name == 'md' ? '10px' : '50px',
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '20px' :
                          _vm.$vuetify.breakpoint.name == 'sm' ? '22px' :
                            _vm.$vuetify.breakpoint.name == 'md' ? '20px' : '40px',
                    })},[_vm._v(" Where Success Knows No Bounds, And "),_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Possibilities ")]),_vm._v("Are "),_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Limitless")]),_vm._v(". ")]),_c('br'),_c('v-flex',{attrs:{"xs12":"","text-justify":""}},[_c('span',{staticStyle:{"color":"#000","font-family":"JostRegular","text-align":"justify"},style:({
                      'line-height':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'xl' ? '25px' : '25px',
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '15px' : _vm.$vuetify.breakpoint.name == 'xl' ? '30px' : '15px',
                    })},[_vm._v("Kumarakom Ventures is a dynamic business aiming to deliver exceptional services globally. Our goal is to contribute to India's growth by empowering clients with knowledge and opportunities. We achieve this through innovative solutions and personalized services, fostering long-term partnerships for sustainable growth. ")])])],1),_c('v-flex',{attrs:{"pt-5":"","px-5":"","text-jus:style":"{\n                  'padding':\n                    $vuetify.breakpoint.name == 'xs' ? '20px' : ($vuetify.breakpoint.name == 'sm' ? '20px' : '10px'),\n  \n                }"}},[_c('span',{staticStyle:{"font-family":"OutfitSemiBold","font-weight":"bold","color":"#1A2421","text-align":"justify"},style:({
                    'line-height':
                      _vm.$vuetify.breakpoint.name == 'xs' ? '30px' : _vm.$vuetify.breakpoint.name == 'md' ? '20px' : '40px',
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'md' ? '18px' : '23px',
                  })},[_c('span',{staticStyle:{"color":"#1B6DC1"}},[_vm._v("Empowering Growth: Our Vision for a Prosperous ")]),_vm._v("Future ")]),_c('br'),_c('v-flex',{attrs:{"text-justify":""}},[_c('span',{staticStyle:{"color":"#000","font-family":"JostRegular","text-align":"justify"},style:({
                      'line-height':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '20px' : _vm.$vuetify.breakpoint.name == 'md' ? '17px' : '25px',
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xs' ? '15px' : _vm.$vuetify.breakpoint.name == 'md' ? '14px' : '15px',
                    })},[_vm._v("At Kumarakom Ventures, our overarching goal is to be a valuable contributor to the fastest-growing Indian economy by empowering our clients with knowledge and confidence, thus linking them to economically beneficial opportunities around the world. We strive to achieve this by providing innovative solutions, strategic insights, and personalized services tailored to the unique needs of each client. Through our dedication to excellence and commitment to fostering long-term partnerships, we aim to drive sustainable growth for our clients, our community, and our nation. ")])])],1)],1)],1)],1)],1),_c('OwlCarousel',{attrs:{"nav":false,"dots":false,"loop":true,"autoplay":true,"autoplaySpeed":1000,"autoplayTimeout":5000,"animateOut":'fadeOut',"responsive":_vm.owlResponsive}},[_vm._l((_vm.itemsCarousel1),function(list,l){return [(list)?_c('v-layout',{key:l,attrs:{"wrap":"","justify-center":""}},[_c('v-flex',[_c('v-img',{attrs:{"height":"300px","src":list.src,"eager":""}})],1)],1):_vm._e()]})],2)],1)]):_vm._e()],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md11":""}},[_c('AboutUs')],1)],1),_c('v-layout',{style:({
    background: 'linear-gradient(356deg, #E1EEFF87 20%, #E1EEFF87 8%, #FFFFFF 35%, #FFFFFF 0%)',

  }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":""}},[_c('services')],1)],1),_c('v-layout',{style:({
    background: 'linear-gradient(10deg, #E1EEFF 0%, #E1EEFF 47%, #FFFFFF 48%, #FFFFFF 100%)',
  }),attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md9":"","lg6":"","py-12":""}},[_c('imageSlider')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }